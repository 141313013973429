@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z1l2o8');
  src:  url('fonts/icomoon.eot?z1l2o8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z1l2o8') format('truetype'),
    url('fonts/icomoon.woff?z1l2o8') format('woff'),
    url('fonts/icomoon.svg?z1l2o8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e900";
}
.icon-booking:before {
  content: "\e901";
}
.icon-bookings:before {
  content: "\e902";
}
.icon-button:before {
  content: "\e903";
}
.icon-calender:before {
  content: "\e904";
}
.icon-calender-2:before {
  content: "\e905";
}
.icon-camera:before {
  content: "\e906";
}
.icon-camera-plus:before {
  content: "\e907";
}
.icon-camps:before {
  content: "\e908";
}
.icon-cart:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-comment:before {
  content: "\e90b";
}
.icon-copy:before {
  content: "\e90c";
}
.icon-create:before {
  content: "\e90d";
}
.icon-cross:before {
  content: "\e90e";
}
.icon-dashboad:before {
  content: "\e90f";
}
.icon-delete:before {
  content: "\e910";
}
.icon-doc-check:before {
  content: "\e911";
}
.icon-download:before {
  content: "\e912";
}
.icon-download-page:before {
  content: "\e913";
}
.icon-draft:before {
  content: "\e914";
}
.icon-email:before {
  content: "\e915";
}
.icon-eye:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-FAQ:before {
  content: "\e918";
}
.icon-feed:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-fire:before {
  content: "\e91b";
}
.icon-folder:before {
  content: "\e91c";
}
.icon-folder-2:before {
  content: "\e91d";
}
.icon-gallery:before {
  content: "\e91e";
}
.icon-heart:before {
  content: "\e91f";
}
.icon-instagram:before {
  content: "\e920";
}
.icon-invite:before {
  content: "\e921";
}
.icon-left-arrow:before {
  content: "\e922";
}
.icon-logo-icon:before {
  content: "\e923";
}
.icon-logout:before {
  content: "\e924";
}
.icon-logout-2:before {
  content: "\e925";
}
.icon-map:before {
  content: "\e926";
}
.icon-mark:before {
  content: "\e927";
}
.icon-offers:before {
  content: "\e928";
}
.icon-pages:before {
  content: "\e929";
}
.icon-pancil:before {
  content: "\e92a";
}
.icon-payments:before {
  content: "\e92b";
}
.icon-phone:before {
  content: "\e92c";
}
.icon-phone-big:before {
  content: "\e92d";
}
.icon-plus:before {
  content: "\e92e";
}
.icon-print:before {
  content: "\e92f";
}
.icon-privacy:before {
  content: "\e930";
}
.icon-QR-code:before {
  content: "\e931";
}
.icon-question:before {
  content: "\e932";
}
.icon-reviews:before {
  content: "\e933";
}
.icon-rewards:before {
  content: "\e934";
}
.icon-search:before {
  content: "\e935";
}
.icon-send:before {
  content: "\e936";
}
.icon-setting:before {
  content: "\e937";
}
.icon-settings:before {
  content: "\e938";
}
.icon-share:before {
  content: "\e939";
}
.icon-share-fill:before {
  content: "\e93a";
}
.icon-star:before {
  content: "\e93b";
}
.icon-twiter:before {
  content: "\e93c";
}
.icon-upload:before {
  content: "\e93d";
}
.icon-upload-img:before {
  content: "\e93e";
}
.icon-user:before {
  content: "\e93f";
}
.icon-users:before {
  content: "\e940";
}
.icon-youtube:before {
  content: "\e941";
}
.icon-advertising:before {
  content: "\e942";
}
.icon-menu-bar:before {
  content: "\e943";
}
.icon-unlock:before {
  content: "\e944";
}
.icon-lock:before {
  content: "\e945";
}
.icon-linkedin:before {
  content: "\e946";
}
.icon-close:before {
  content: "\e947";
}
.icon-donation:before {
  content: "\e948";
}
.icon-upload-2:before {
  content: "\e949";
}
.icon-add-media:before {
  content: "\e94a";
}
.icon-add-doc:before {
  content: "\e94b";
}
.icon-add-date:before {
  content: "\e94c";
}
.icon-upload-cercle:before {
  content: "\e94d";
}
.icon-smile:before {
  content: "\e94e";
}
.icon-heart-fill:before {
  content: "\e94f";
  color: #842014;
}
.icon-information:before {
  content: "\e950";
}
.icon-photo-friendly:before {
  content: "\e951";
  color: #842014;
}
.icon-hospital:before {
  content: "\e952";
  color: #842014;
}
.icon-handshake:before {
  content: "\e953";
  color: #842014;
}
.icon-fish:before {
  content: "\e954";
  color: #842014;
}
.icon-deadly-allergy:before {
  content: "\e955";
  color: #842014;
}
.icon-play-button:before {
  content: "\e956";
}
.icon-smile-line:before {
  content: "\e957";
  color: #747474;
}
