


@media(min-width: 768px){
    .chat_mob_trigger {
        display: none !important;
    }

    .add_prog_modal {
        max-width: 100%;
    }
}

@media(min-width: 992px){
    .add_prog_modal {
        max-width: 1140px;
    }
}


@media(max-width: 1360px){
    .cm_100_small_screen {
        display: block !important;
    }

    .cm_centered {
        width: 220px;
        margin: 0 auto;
    }

    .res_cm_max_height {
        max-height: none !important;
    }


}


@media(max-width: 1023px){

    .hide_sidebar {
        overflow: hidden;
    }

    .sidebar {
        left: -230px;
    }

    .hide_sidebar .sidebar {
        left: 0;
    }

    header {
        width: 100%;
        margin-left: 0;
    }

    .hide_sidebar .page_wrapper,
    .page_wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    .menu_overlay {
        position: fixed;
        width: 0;
        height: 100%;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        cursor: e-resize;
        transition: all 0.225s;
    }

    .hide_sidebar .menu_overlay {
        width: calc(100% - 220px);
    }

}

@media(max-width: 991px){
    .cm_hide_mob {
        display: none !important;
    }

    .fp_wrapper .col-lg-4 {
        padding: 3rem;
    }
}

@media(max-width: 767px){
    .cht_users {
        position: absolute;
        background: #fff;
        z-index: 99;
        width: 100%;
        transform: translateX(-140%);
        transition: all 0.3s;
        top: 0;
    }

    .chat_user_head {
        justify-content: flex-end;
    }

    .chat_mob_trigger {
        position: absolute;
        left: 15px;
        top: 20px;
        cursor: pointer;
    }

    .toogle_msg_sider .cht_users {
        transform: translateX(0%);
    }
}

