@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Semibold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'kohinoor';
    src: url('../fonts/KohinoorTelugu/KohinoorTelugu-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@import '../fonts/icomoon/style.css';

.main_loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.96);
}

.main_loader img,
.main_loader > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main_loader > svg {
    width: 250px;
}

.main_loader img {
    width: 100px;
    z-index: 999;
}

.loader_wrap .main_loader {
    position: absolute;
    right: 310px;
    left: auto !important;
    top: 0;
    width: 40px;
    height: 40px;
    background: transparent;
}

.loader_wrap svg {
    width: 50px;
}

body {
    font-family: 'kohinoor', sans-serif;
    font-size: 16px;
    color: #747474;
    background: #F7F7F7;
}

* {
    scrollbar-color: #8E8E8E #f1f1f1;
    scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
border-radius: 0px 0px 10px 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #8E8E8E; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #842014; 
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #2B2B2B;
    font-weight: 600;
}

h1, .h1 {
    font-size: 2.5rem;
}
  
h2, .h2 {
    font-size: 2rem;
}
  
h3, .h3 {
    font-size: 1.75rem;
}
  
h4, .h4 {
    font-size: 1.5rem;
}
  
h5, .h5 {
    font-size: 1.25rem;
}
  
h6, .h6 {
    font-size: 1rem;
}



  
.modal-backdrop.show {
    opacity: .3;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
}

.carousel-indicators .active:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
}


.form-control {
    height: calc(1.5em + .75rem + 4px);
    border: 1px solid #8E8E8E;
    border-radius: 40px;
}

.btn {
    padding: 0.44rem 2rem;
    border-radius: 40px;
}
.auth_wrapper .btn {
    padding: 0.75rem 2rem;
}

.btn-primary.focus, .btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary {
    background-color: #842014;
    border-color: #842014;
    box-shadow: none;
}
.btn-gray{
    background: #D8D6D6;
}

.btn-white{
    background-color: #fff;
    padding: 10px;
    color: #842014;
}

.btn-primary:hover {
    background-color: #56130a;
    border-color: #56130a;
}

.btn-light {
    color: #3E3E3E;
    background-color: #D8D6D6;
    border-color: #D8D6D6;
}

.cm_link_red {
    color: #842014;
    font-weight: 600;
    cursor: pointer;
}

.cm_link {
    cursor: pointer;
    color: #2B2B2B;
}

.pointer {
    cursor: pointer;
}

.check_wrapper {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
}

.auth_wrapper .check_wrapper{
    top: 3px;
}

.check_wrapper > span {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 2px solid #000;
    border-radius: 2px;
}

.check_wrapper > span:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 12px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(35deg);
    left: 5px;
    top: 1px;
    transition: all 0.25s;
    opacity: 0;
}

.check_wrapper input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
}

.check_wrapper input:checked + span {
    background: #842014;
    border-color: #842014;
}

.check_wrapper input:checked + span:after {
    opacity: 1;
}

.modal .close {
    position: absolute;
    right: 18px;
    top: 16px;
    border: 2px solid #3E3E3E;
    border-radius: 100%;
    opacity: 1;
    margin: 0 !important;
    width: 30px;
    height: 30px;
    padding: 0;
    outline: none;
}

.modal-title {
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.modal-header {
    border: 0;
    padding: 20px;
}

.modal-content {
    border: 0;
    border-radius: 10px;
}

.modal-header {
    align-items: center;
    justify-content: center;
}

.btn-outline-primary {
    color: #842014;
    border-color: #842014;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #842014;
    border-color: #842014;
}

.btn > i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.invalid_feild {
    color: red;
    font-size: 80%;
    margin-top: 8px;
}

.table {
    font-size: 14px;
}

.table .t_text {
    display: inline-block;
    min-width: 40px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 240px;
}



.form_control__control {
    border-radius: 0 !important;
    border: 0 !important;
}

.form_control__control--is-focused {
    box-shadow: none !important;
}

.form_control__menu {
    color: #000;
}

.form_control__indicator-separator {
    display: none;
}

.form_control__indicator {
    color: #3E3E3E !important;
}

.modal_close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #3E3E3E;
}

.modal_close:hover {
    color: #842014;
}


.cm_empty svg {
    width: 125px;
}

.cm_empty {
    text-align: center;
}


.tooltip-inner{background-color: #842014 }
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #842014;
}

.error_tootltip {
    position: absolute;
    width: 40px;
    height: 40px;
    background: transparent;
    right: 0;
    top: 0;
    cursor: pointer;
}

.error_tootltip_label {
    position: absolute;
    width: 40px;
    height: 40px;
    background: transparent;
    right: 15px;
    top: 32px;
    cursor: pointer;
}

.cm_top_0 .error_tootltip_label {
    right: 23px;
    top: 0;
}

.modal-body .error_tootltip {
    right: 0px;
    top: auto;
    bottom: 0;
}

.modal-body .col-md-6.form-group .error_tootltip {
    right: 15px;
}

.form-group {
    position: relative;
}

.icon-delete {
    cursor: pointer;
}


.pagination {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0;
    padding: 0 15px;
}

.pagination li {
    margin-left: 5px;
}

.pagination li a {
    display: block;
    width: 28px;
    height: 28px;
    background: #e5e5e5;
    text-align: center;
    line-height: 28px;
    color: #842014;
    border-radius: 4px;
    font-size: 14px;
    text-decoration: none;
}

.pagination li a:hover,
.pagination li.active a {
    background: #842014;
    color: #fff;
}

.pagination li.disabled a {
    background: #f7f7f7;
    color: #c5c5c5;
    cursor: not-allowed;
}


input.PhoneInputInput {
    border: 0;
    outline: none;
}

label {
    font-weight: 500;
    color: #000;
}

.modal-body .ant-picker,
.modal-body .ant-input-number {
    height: 40px;
}

.modal-body .ant-input-number-input {
    padding: .375rem .75rem;
    font-size: 1rem;
    height: 37px;
}

.cm_select__control {
    border: 0 !important;
    background: transparent !important;
    position: relative !important;
    top: -5px !important;
    box-shadow: none !important; 
}

.cm_select__value-container {
    flex-wrap: nowrap !important;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #dedede;
    border-color: #dedede;
}

.btn:disabled, .btn.disabled{
    cursor: no-drop;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #e5e5e5;
    border-color: #e5e5e5;
}


.cm_locations {
    position: absolute;
    max-height: 400px;
    width: calc(100% - 30px);
    left: 15px;
    top: 78px;
    border-radius: 4px;
    overflow: auto;
    z-index: 999;
    background-color: #fff;
}

.cm_locations .suggestion-item {
    background: #fff;
    display: block;
    padding: 10px 15px;
    border: 1px solid #e4e4e4;
}
.cm_locations .suggestion-item--active {
    background: #80bdff;
    display: block;
    padding: 10px 15px;
    color: #fff;
    border: 1px solid #e4e4e4;
    cursor: pointer;
}


.ant-picker-suffix {
    align-self: baseline;
}

.ant-picker-range-separator {
    display: none !important;
}

.ant-input-number:hover,
.ant-picker:hover {
    border-color: inherit;
}

.ant-input-number:focus, .ant-input-number-focused {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.ant-input-number-focused:hover {
    border-color: #40a9ff;
}

.ant-empty-description {
    color: #842014;
    font-size: 16px;
    font-weight: 600;
}

.btn-outline-danger {
    color: #842014;
    border-color: #842014;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #842014;
    border-color: #842014;
}

.btn-outline-danger.btn-sm {
    padding: 3px 12px;
}

.btn-outline-danger.btn-sm i {
    font-size: 11px;
    margin-right: 0px;
}

.btn-outline-dark {
    color: #8E8E8E;
    border-color: #8E8E8E;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle,
.btn-outline-dark:hover,
.btn-outline-dark.active,
.btn-outline-dark.cm_active,
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #842014;
    border-color: #842014;
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.ant-notification {
    z-index: 99999;
    
}

.ant-notification-notice {
    border-radius: 10px;
}

/* .ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
    display: none !important;
} */


