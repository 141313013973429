.auth_wrapper {
    background: #F9A720;
    height: 100vh;
    min-height: 750px;
    position: relative;
    padding-bottom: 100px;
    padding-top: 100px;
}

.auth_wrapper .container {
    background-color: #fff;
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 5px 30px rgba(0,0,0,0.1);
    position: relative;
    height: 625px;
}

.auth_wrapper .container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url('../img/patten.png'), url('../img/patten.png');
    background-repeat: no-repeat;
    background-position: calc(50% - 40px) 40px, calc(100% - 40px) calc(100% - 5px);
    opacity: 0.12;
}

.auth_wrapper.fp_wrapper .container:after {
    background-position: top left, right bottom;
}

.copyright {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    font-size: 12px;
    color: #fff;
}

.auth_logo {
    position: absolute;
    right: 15px;
    top: 15px;
}

.auth_wrapper .container .row {
    position: relative;
    z-index: 999;
}

.auth_wrapper .form-control {
    border: 0;
    border-bottom: 1px solid #C6C6C6;
    border-radius: 0;
    padding-left: 0;
}

.auth_wrapper .form-control:focus {
    border-color: #842014;
    box-shadow: none;
}


.auth_wrapper .form-controls, .css-yk16xz-control {
    border: 0;
    border-bottom: 1px solid #C6C6C6;
    border-radius: 0;
    padding-left: 0;
}

.css-1pahdxg-control{
    border-radius: 25px !important;
    border-color: #8E8E8E !important;
}

.css-yk16xz-control{
    border-radius: 25px !important;
    border-color: #8E8E8E !important;
}


.auth_wrapper .form-control:focus {
    border-color: #842014;
    box-shadow: none;
}

.icon_input {
    position: relative;
}

.icon_input i {
    position: absolute;
    right: 32px;
    top: 12px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.26);
}

.icon-eye.slash:after {
    content: '';
    width: 3px;
    height: 20px;
    background: #bdbdbd;
    position: absolute;
    left: 6px;
    top: -2px;
    transform: rotate(45deg);
    border-radius: 3px;
}

p.success_text {
    font-size: 13px;
    margin: 35px 0;
}

.error_msg {
    text-align: center;
    color: red;
    margin-top: 20px;
}




.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 220px;
    background: white;
    box-shadow: 0 5px 10px rgba(0,0,0,0.05);
    height: 100%;
    overflow: auto;
    padding: 15px;
    transition: all 0.225s;
    z-index: 999;
}

.hide_sidebar .sidebar {
    left: -230px;
}

.cm_logo {
    text-align: center;
}

.cm_logo img {
    width: 110px;
    margin-bottom: 10px;
}

.cm_logo_large img {
    width: 100%;
    max-width: 550px;
    margin-bottom: 50px;
    height: auto; 
}

@media (max-width: 768px) {
    .cm_logo_large img {
        max-width: 400px; 
        margin-bottom: 30px; 
    }
}

@media (max-width: 480px) {
    .cm_logo_large img {
        max-width: 250px; 
        margin-bottom: 20px; 
    }
}

.cm_logo p {
    font-weight: 600;
    color: #000;
    font-size: 14px;
}

.sidebar ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar ul li a {
    display: block;
    padding: 9px 10px;
    color: #8E8E8E;
    font-size: 14px;
    position: relative;
}

.unread_sport {
    position: absolute;
    font-size: 11px;
    background: #f9a720;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    right: 0;
    top: 50%;
    width: 22px;
    height: 22px;
    line-height: 22px;
    transform: translateY(-50%);
}

.sidebar ul li a.active,
.sidebar ul li a:hover {
    text-decoration: none;
    color: #842014;
}

.sidebar ul li a i {
    display: inline-block;
    width: 35px;
    color: #8E8E8E;
    font-size: 20px;
    vertical-align: middle;
}

.sidebar ul li a i:before {
    color: #8E8E8E;
}

.sidebar ul li a.active i:before, 
.sidebar ul li a:hover i:before  {
    color: #842014;
}


header {
    width: calc(100% - 220px);
    padding: 28px 20px;
    margin-left: 220px;
    position: relative;
    padding-left: 80px;
    transition: all 0.225s;
}

.hide_sidebar header {
    width: 100%;
    margin-left: 0;
}

.side_menu_toggler {
    position: absolute;
    font-size: 26px;
    left: 20px;
    cursor: pointer;
    color: #842014;
    top: 26px;
}

.cm_breadcrumb li {
    display: inline-block;
    margin-right: 10px;
    color: #000;
}

.cm_breadcrumb li:after {
    content: '-';
    display: inline-block;
    margin-left: 10px;
}

.cm_breadcrumb li:last-child:after,
.cm_breadcrumb li:first-child:after {
display: none;
}

.cm_breadcrumb li a {
    color: #000;
}

.cm_breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;
}

.page_wrapper {
    padding-left: 240px;
    padding-right: 20px;
    margin-top: 15px;
    transition: all 0.225s;
}

.hide_sidebar .page_wrapper {
    padding-left: 20px;
}




.dash_card {
    padding: 20px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.11);
    border-radius: 10px;
    color: #000;
    position: relative;
}

.dash_card .main_loader {
    position: absolute !important;
    border-radius: 10px;
}

.abs_wrap {
    position: relative;
}

.abs_wrap a {
    position: absolute;
    right: 0;
    top: 0;
    color: #842014;
    text-decoration: underline;
}

.dash_card.h_225 {
    height: 225px;
}









.page_card {
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    background: #fff;
    border-radius: 10px;
    color: #000;
    padding-bottom: 30px;
}

.page_card_header {
    border-bottom: 1px solid rgba(198, 198, 198, 0.50);
    padding: 10px 15px;
    min-height: 61px;
}

.page_card_header h4 {
    margin: 0;
    font-size: 16px;
    color: #842014;
    line-height: 40px;
}

.page_card_body {
    padding: 15px;
    position: relative;
}

.card.cat_card {
    padding: 15px;
    color: #000;
    border: 1px solid #C6C6C6;
    border-radius: 10px;
    padding-bottom: 0;
    margin-bottom: 30px;
}

.cat_card_img {
    width: 100px;
    height: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 10px auto;
    justify-content: center;
    background: #842014;
    border-radius: 5px;
}

.cat_card_img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.card.cat_card p {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}


.cat_card_action {
    margin: 0 -15px;
    border-top: 1px solid #C6C6C6;
    justify-content: space-evenly;
    padding: 10px;
    align-items: center;
    background: #FCFCFC;
    border-radius: 0 0 10px 10px;
}

.cat_card_action i {
    cursor: pointer;
}

.cm_swich_wrap {
    position: relative;
    height: 18px;
}


.cm_swich_wrap input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
}

.cm_swich_wrap > span {
    background: #8e8e8e;
    display: inline-block;
    width: 30px;
    height: 16px;
    border-radius: 14px;
    transition: all 0.3s;
    vertical-align: text-top;
}

.cm_swich_wrap > span:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 100%;
    top: 4px;
    left: 3px;
    transition: all 0.3s;
}

.cm_swich_wrap input:checked + span {
    background: #3EE84B;
}

.cm_swich_wrap input:checked + span:after {
    left: 16px;
}

.cat_img_box {
    border: 1px solid #C6C6C6;
    width: 300px;
    height: 150px;
    border-radius: 10px;
    text-align: center;
    line-height: 150px;
    margin-bottom: 35px;
    background: #f7f7f7;
}

.cat_img_box img {
    max-width: 100px;
    max-height: 100px;
}

.additional-type {
    border-top: 1px solid #C6C6C6;
    margin-top: 30px;
    padding-top: 20px;
}

.additional-type ol {
    margin: 0;
    padding-left: 19px;
}
.additional-type ol li {
    color: #5A5A5A;
}

.search_wraper {
    position: absolute;
    width: 300px;
    top: 18px;
    right: 76px;
}

.search_wraper i {
    position: absolute;
    left: 12px;
    top: 12px;
    color: #8E8E8E;
}

.search_wraper .form-control {
    padding-left: 35px;
}

.notification_bell {
    position: absolute;
    right: 30px;
    top: 24px;
}

.notification_bell i {
    color: #F9A720;
    font-size: 26px;
}

.notification_bell span {
    position: absolute;
    background: #842014;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 11px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    right: -15px;
    top: -7px;
    border: 2px solid #f7f7f7;
    box-sizing: content-box;
}

.icon_upload_wrapper {
    width: 300px;
    height: 150px;
    background: #F1F1F1;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
}

.icon_upload_wrapper img {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.icon_upload_wrapper > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.cm_edit_mode {
    transform: none !important;
    right: 14px;
    left: auto !important;
    top: auto !important;
    bottom: 0;
}

.icon_upload_wrapper > div i {
    font-size: 46px;
    margin-bottom: 18px;
    color: #8E8E8E;
    display: inline-block;
}

.cm_edit_mode i {
    font-size: 24px !important;
}

.icon_upload_wrapper p {
    margin: 0;
}

.icon_upload_wrapper input {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0;
    z-index: 99;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
}

.table-responsive {
    border: 1px solid #C6C6C6;
    border-radius: 10px;
    border-top: 0;
}
.table thead th {
    border-bottom: 0;
    border-top: 0;
    background: #E5E5E5;
    border-right: 1px solid #C6C6C6;
}

/* .table-bordered td {
    border: 1px solid #c6c6c6;
} */

table {
    margin-bottom: 0 !important;
    position: relative;
    z-index: 99;
}

.table_responsive_wrap {
    position: relative;
}



.table-responsive:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 49px;
    background: #e5e5e5;
    left: 0;
    top: 0;
    border-radius: 10px 10px 0 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #e5e5e5;
}

table .cm_swich_wrap {
    display: inline-block;
    vertical-align: middle;
}

.add_type_btn {
    color: #842014;
    margin: 20px 0;
}

.add_type_btn span {
    display: inline-block;
    cursor: pointer;
}

.date_icon {
    right: 30px !important;
    top: 11px !important;
    color: #3E3E3E !important;
}

.table_header {
    margin-top: 30px;
}

.table_header h4 {
    margin: 0;
    font-size: 16px;
}

.table_page_set select {
    margin-left: 20px;
    border: 1px solid #8E8E8E;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    min-width: 50px;
    padding: 0 5px;
    -webkit-appearance: none;
    appearance: none!important;

}

.table_filters {
    position: relative;
    z-index: 999;
}

.mini_loader {
    bottom: 8px;
    width: 33px;
    display: inline-block;
    position: relative;
}

.t_text .mini_loader {
    bottom: 3px;
    width: 21px;
}

.doughnut svg {
    width: 220px;
    height: 220px;
    transform: rotate(-90deg);
}

.cm_max_height {
    max-height: 453px;
}

.check_green {
    position: absolute;
    width: 22px;
    height: 4px;
    background: #33CB05;
    transform: rotate(-45deg);
    border-radius: 5px;
    right: 0;
    top: 12px;
}

.check_green:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 12px;
    background: #33CB05;
    border-radius: 5px;
    top: -9px;
    left: 0;
}

.cm_abs {
    position: absolute;
    left: 0;
    bottom: -20px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #842014;
    border-bottom: 2px solid #842014;
    font-weight: 600;

}
.nav-tabs .nav-link{
    border: none;
}
.nav-tabs a{
    color: #8E8E8E;
}
.nav-link {
    padding: 0.5rem 2rem;
}
.nav-tab:focus{
    border: none;
}

select {
    border: none;
    background-color: transparent;
}
table .form-group {
     margin-bottom: 0rem;
}

.btn_wrapper{
    position: absolute;
    top: 8px;
    right: 15px;
}
.bold{
    color: #000;
    font-weight: 500;
}

.cm_checkmark{
    border-color: #000;
    border: 3px solid #000;
    margin-top: 0px;
    height: 25px;
    width: 25px;
    padding: 0px !important;
}
.btn__group {
    margin: 30px 0;
}

.btn__group .btn {
    color: #B4B4B4;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 5px;
}

.btn__group .btn:hover,
.btn__group .btn.active {
    background: #5A5A5A;
    color: #fff;
}

.people_count_bar {
    background: #eee;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 30px 0;
}

.mini_graph_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.mini_graph_list li {
    display: inline-block;
    width: calc(100% / 3);
    padding-right: 30px;
    padding-bottom: 50px;
}

.mini_graph_list h5 {
    font-size: 14px;
    color: #8E8E8E;
    font-weight: 400;
}

.mini_graph_list h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.mini_graph_list canvas {
    height: 60px !important;
}

.tab_btns {
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 15px;
}

.tab_btns span {
    display: inline-block;
    color: #8E8E8E;
    padding: 12px 25px;
    line-height: 1;
    position: relative;
    cursor: pointer;
}

.tab_btns span.active {
    font-weight: 600;
    color: #842014;
}

.tab_btns span:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 2px;
    background: #842014;
    left: 0;
    bottom: -16px;
    transition: all 0.25s;
}

.tab_btns span.active:after {
    width: 100%;
}

.cm_or_line {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
}

.cm_or_line:after {content: '';position: absolute;width: 100%;height: 1px;background: #C6C6C6;left: 0;top: 50%;transform: translateY(-50%);}

.cm_or_line > span {
    display: inline-block;
    background: #fff;
    position: relative;
    z-index: 9;
    padding: 10px 20px;
    color: #000;
    font-weight: 600;
}

.t_text i {
    margin-right: 15px;
    cursor: pointer;
}

.term_img {
    height: 20px;
    width: 30px;
    object-fit: cover;
    border-radius: 3px;
}



.DraftEditor-root {
    border: 1px solid #8E8E8E;
    border-radius: 10px;
    min-height: 130px;
    padding: 10px 15px;
}

.messageInput{
  height: 100px !important;
    border-radius: 10px;

}
.messageInput::placeholder{
    margin-top: 0 !important;
}

#inputTitle{
    border-radius: 0px;
    border-top: 2px solid rgba(219, 232, 223, 0.5);
    border-right: 2px solid rgba(219, 232, 223, 0.5);
}

#linkInput {
    border-radius: 0px;
    border-bottom: 2px solid rgba(219, 232, 223, 0.5);
    border-right: 2px solid rgba(219, 232, 223, 0.5);
}

.round-border{
    border-radius: 100%;
}
.cm_space {
    display: inline-block;
    width: 30px;
    height: 1px;
}


.camp_logo_upload {
    width: 205px;
    height: 190px;
    background: #F1F1F1;
    border-radius: 10px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
}

.camp_logo_upload i {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #8E8E8E;
    font-size: 52px;
    top: 45px;
}

.camp_logo_upload i.icon-create {
    font-size: 16px;
    background: #842014;
    transform: none;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.26);
    padding: 8px;
    border-radius: 4px;
    left: auto;
    bottom: 0;
    top: auto;
    right: 0;
    color: #fff;
}

.camp_logo_upload p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
    color: #8E8E8E;
}

.camp_logo_upload input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    z-index: 9;
    cursor: pointer;
    opacity: 0;
}

.add_camp_wrap {
    border-bottom: 1px solid #C6C6C6;
    padding: 15px 0;
}

.add_camp_wrap p b {
    display: inline-block;
    min-width: 180px;
}

.camp_logo_upload img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    max-height: 100%;
}

.camp_logo_upload .invalid_feild {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 6px;
}

.very_big_table thead th {
    vertical-align: middle;
}

.t_input {
    min-width: 180px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


.t_input .ant-select {
    color: #495057;
    text-align: left;
}

.t_input .ant-select-selector {
    background-color: transparent !important;
    border: 0 !important;
    font-size: .875rem;
    line-height: 1.5;
    top: 2px;
    box-shadow: none !important;

}

.ant-input-number {
    width: 100%;
}

.ant-input-number-handler-wrap {
    display: none;
}

.ant-input-number-input-wrap {
    height: 33px;
}

input.ant-input-number-input {
    font-size: .875rem;
    line-height: 1.5;
    box-shadow: none !important;
    height: 100%;
}

.camp_detail {
    font-size: 14px;
}

.camp_detail p b {
    min-width: 180px;
    display: inline-block;
}

.forgot_link {
    color: #5A5A5A;
}

.color_black {
    color: #000;
}

.fp_wrapper h2 {
    font-weight: 500;
}

.form-group__value-container {
    flex-wrap: nowrap;
}

.input_space_form .form-group {
    margin-bottom: 30px;
}

.child_el {
    position: relative;
}

.child_el .btn_wrapper {
    top: -74px;
}

.cm_textarea {
    min-height: 190px;
    border-radius: 15px;
    resize: none;
}

.cm_textarea + .error_tootltip {
    top: 34px !important;
    bottom: auto !important;
}



.cht_users {
    width: 300px;
    border-right: 1px solid #e5e5e5;
    padding: 15px;
    height: 100%;
    overflow: auto;
    position: relative;
}

.cht_users ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat_msg img,
.chat_user_head img,
.cht_users ul .chat_user_img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.cht_users ul p {
    margin: 0;
    font-size: 13px;
    color: #3E3E3E;
}

.cht_users ul li {
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
}

.contact_search input {
    border: 0;
    border-bottom: 2px solid #E5E5E5;
    padding: 7px;
    padding-left: 24px;
    width: 100%;
    font-size: 15px;
}

.contact_search input:focus {
    outline: none;
    border-bottom: 2px solid #842014;
}

.contact_search {
    position: sticky;
    background: white;
    padding: 0 15px;
    margin: -15px;
    margin-bottom: 15px;
    z-index: 9;
    top: -15px;
}

.contact_search i {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #8E8E8E;
}

.chat_wrapper {
    height: calc(100vh - 175px);
    min-height: 500px;
}


.chat_user_head {
    border-bottom: 1px solid #E5E5E5;
    width: 100% !important;
    padding: 10px;
}

.chat_user_head p {
    margin: 0;
    font-size: 14px;
}

.chat_user_head p span {
    color: #747474;
    font-weight: 400;
}

.chat_text_area {
    padding: 15px;
    position: relative;
}

.mt-auto.chat_text_area textarea {
    min-height: 50px !important;
    max-height: 170px !important;
    resize: none;
    border: 1px solid #E5E5E5;
    padding-top: 12px;
}

span.send_btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #842014;
    cursor: pointer;
    font-size: 20px;
}

.chat_screen {
    overflow: auto;
    position: relative;
}

.chat_msg {
    position: relative;
    margin: 40px 0;
    padding: 20px;
    padding-top: 40px !important;
}



.chat_msg img {
    position: absolute;
    left: 20px;
    top: 0;
}

.cm_name_m {
    position: absolute;
    left: 60px;
    top: 5px;
    font-weight: 400;
    color: #52575D;
}

.cm_name_m time {
    color: #969798;
    font-size: 14px;
    margin-left: 10px;
}

.chat_msg .msg_text {
    padding: 12px 20px;
    margin: 0;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
    max-width: 75%;
    display: inline-block;
}

.chat_msg.other_user .msg_text {
    background: rgba(249, 167, 32, 0.5);
}

.chat_msg.me_user .msg_text {
    background: rgba(155, 155, 155, 0.1);
}


.chat_msg.me_user {
    text-align: right;
}

.chat_msg.me_user p {
    text-align: left;
}


.chat_msg.me_user .cm_name_m {
    left: auto;
    right: 20px;
}











.program_head {
    border-bottom: 1px solid #B4B4B4;
    margin-bottom: 30px;
    padding: 30px 0;
    padding-bottom: 5px;
}

.program_head h2{
    font-size: 26px;
    margin: 0;
}

.ratings .icon-star {
    margin-left: 2px;
    color: #FFC107;
}

.ratings {
    color: #2B2B2B;
}

.program_page h3 {
    color: #842014;
    font-size: 22px;
    margin: 0;
}

.quantity button {
    display: inline-block;
    width: 26px;
    height: 26px;
    background: #D8D6D6;
    cursor: pointer;
    position: relative;
    border: 0;
    outline: none;
}

.quantity {
    margin-top: 12px;
}

.quantity > * {
    vertical-align: middle;
}

.quantity b {
    display: inline-block;
    line-height: 26px;
    height: 26px;
    padding: 0 12px;
    color: #2B2B2B;
    font-weight: 500;
}

.quantity .dec:after,
.quantity .inc:after,
.quantity .dec:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 2px;
    background: #888888;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 2px;
}

.quantity .dec:before {
    width: 2px !important;
    height: 15px !important;
}

.quantity button:hover {
    background: #842014;
}

.quantity button:hover:after,
.quantity button:hover:before {
    background: #fff;
}

.prog_price h3 {
    color: #888888;
    font-weight: 500;
    font-size: 19px;
}

.prog_price h3 b {
    font-weight: 600;
    color: #842014;
    font-size: 22px;
}

.prog_price small {
    color: #48A7FC;
    font-weight: 500;
}

.daily_schedule {
    background: #F6F6F4;
    border-radius: 5px;
}


.daily_schedule {
    background: #F6F6F4;
    border-radius: 5px;
    padding: 20px;
    padding-right: 0;
}

.daily_schedule ul {
    margin: 0;
    list-style: none;
    padding: 20px 10px 20px 20px;
    border-left: 1px solid #E8E8E8;
}

.daily_schedule ul li {
    position: relative;
    margin-bottom: 20px;
    word-break: break-all;
}

.daily_schedule ul li b {
    display: block;
    color: #842014;
    font-weight: 500;
}

.daily_schedule ul li:before {content: '';position: absolute;width: 13px;height: 13px;background: #ffc107;border-radius: 100%;left: -27px;top: 7px;}

.program_detail .cm_bordered {
    border-left: 1px solid #842014;
    border-right: 1px solid #842014;
}

.program_detail p {
    margin: 15px 0;
}

.program_detail p b {
    color: #2B2B2B;
    font-weight: 500;
}





.img_upload_wrap img {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    margin-right: 30px;
    object-fit: cover;
}

.img_upload_wrap h4 {
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
    margin-right: 30px;
    color: #842014;
    font-weight: 500;
}

.cm_form_title {
    background: #E5E5E5;
    border-radius: 5px;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 15px;
    margin-bottom: 25px;
}


textarea.rounded {
    border-radius: 20px !important;
}


ul.program_imgs {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.program_imgs li {
    display: inline-block;
    border: 1px solid #8e8e8e;
    border-radius: 5px;
    margin-right: 15px;
    position: relative;
    margin-bottom: 15px;
}

ul.program_imgs li img {
    width: 160px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}
ul.program_imgs.program_imgs_for_mob li img {
    width: 100px;
    height: 100px;
}

ul.program_imgs li i {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    background: #fff;
    padding: 2px;
    border-radius: 3px;
    padding-left: 3px;
}

.prog_media {
    min-height: 400px;
    position: relative;
}

.prog_slide_img img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.prog_media .slick-arrow.slick-next,
.prog_media .slick-arrow.slick-prev {
    background: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    z-index: 99;
}


.prog_media .slick-arrow.slick-prev {
    left: 15px;
}

.prog_media .slick-arrow.slick-next {
    right: 15px;
}

.prog_media .slick-next:before,
.prog_media .slick-prev:before {
    content: '';
    width: 10px;
    height: 10px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    opacity: 1;
    display: inline-block;
    transform: rotate(45deg);
    position: absolute;
    left: 11px;
    top: 10px;
}

.prog_media .slick-next:before {
    transform: rotate(-135deg);
    left: 8px;
}

.no_img {
    background: #D8D6D6;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;

}

.no_img svg {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ant-picker-clear {
    display: none !important;
}


.inputs_view {
    border-bottom: 1px solid #dadada;
    padding-bottom: 10px;
}

.res_error{
    text-align: center;
    color: red;
    font-weight: 500;
    font-size: 18px;
    margin-top: 13px;
}

.popup_loader .main_loader {
    position: absolute;
}

.cm_feild_err {
    text-align: center;
    color: #dc3545;
    font-size: 14px;
}


.ReactCrop {
    margin: 25px 0;
}

.page_wrapper.error_boundary .page_card {
    min-height: calc(100vh - 120px);
}

tr.cm_pink {
    background: #f9a720 !important;
    color: #fff;
}

tr.cm_pink i {
    color: #fff !important;
}

.cm_select__value-container {
    display: block !important;
    white-space: nowrap;
    overflow: auto !important;
}

.cm_select__value-container > div {
    display: inline-block;
    white-space: nowrap;
    position: relative;
}

.cm_select__multi-value {
    padding-right: 20px;
}

.cm_select__multi-value__remove {
    position: absolute;
    right: 0;
    top: 6px;
    cursor: pointer;
}

.cm_select__placeholder {
    top: 10px !important;
}

.css-b8ldur-Input {
    top: -8px !important;
}

.cm_select__single-value {
    top: 15px !important;
}

.child_table td {
    border: 0 !important;
}

.schedule_item {
    background: #F1F1F1;
    margin-bottom: 15px;
    position: relative;
    padding: 10px 15px;
    border-radius: 5px;
    padding-right: 60px;
}

.schedule_item p span,
.schedule_item h6 span {
    display: inline-block;
    vertical-align: text-top;
}

.schedule_item b {
    display: inline-block;
    min-width: 150px;
    font-weight: 400;
}

.program_head h2 img {
    max-width: 52px;
    max-height: 52px;
    margin-right: 15px;
}

.schedule_item p {
    margin: 0;
    color: #2B2B2B;
    word-break: break-all;
}

.cm_action {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #2B2B2B;
}

.cm_action i {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.cm_faq_block {
    margin-bottom: 50px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 5px;
    background: #f9f9f9;
}

.faq_q {
    color: #842014;
    font-weight: 500;
    font-size: 14px;
}

.faq_a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.btn_mini_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(255 255 255 / 73%);
}

.btn_mini_loader svg {
    width: 160px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.last_dot span:last-child {
    display: none !important;
}

.program_pdf {
    display: inline-block;
    position: relative;
    width: 11px;
    margin-right: 10px;
}

.program_pdf input {
    position: absolute;
    width: 100%;
    height: 20px;
    font-size: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
}

.camp_detail_map {
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
}

.camp_detail_address {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #747474;
    color: #fff;
    padding: 10px;
}


.info_sec {
    border-bottom: 1px solid #C6C6C6;
    margin-bottom: 30px;
}


.info_sec_bao_img {
    display: inline-block;
    width: 300px;
    vertical-align: top;
    text-align: center;
}

.info_sec_bao_img img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    object-fit: cover;
}

.info_sec_bao_img h3 {
    font-size: 18px;
    margin-top: 20px;
    color: #000;
    font-weight: 500;
}

.info_sec_bao {
    display: inline-block;
    width: calc(100% - 301px);
    vertical-align: top;
}

.info_sec p {
    font-size: 15px;
}

.info_sec p b {
    display: inline-block;
    min-width: 250px;
}

.table_loader {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: #fff;
    z-index: 999;
}

.table_loader .main_loader {
    position: absolute;
}

.cm_phone_field {
    position: relative;
}

.cm_phone_field .us_flag {
    position: absolute;
    top: 12px;
    left: 12px;
}

.cm_phone_field .form-control {
    padding-left: 40px;
}

.info_sec h4 {
    color: #842014;
    font-size: 20px;
    margin-bottom: 20px;
}

.info_sec h5 {
    font-size: 18px;
    color: #a2a2a2;
    font-weight: 500;
    margin-top: 15px;
}


.search_wrapper {
    background: #EBEAEA;
    margin: 0 -1rem;
    position: relative;
}

.search_wrapper input {
    border: 0;
    background: transparent;
    padding: 15px;
    width: 100%;
    padding-left: 45px;
    outline: none;
}

.search_wrapper i {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.user_list {
    max-height: 350px;
    overflow: auto;
    padding-right: 12px;
    margin: 15px 0;
}

.user_list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.user_list ul li {
    display: flex;
    align-items: center!important;
    border-bottom: 1px solid #D8D6D6;
    padding: 15px 0;
    cursor: pointer;
}

.user_list ul li .check_wrapper {
    margin-left: auto;
    height: 18px;
    margin-right: 0;
}

.user_list ul li img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
}

.user_list ul li p {
    margin: 0;
}

.group_name input {
    border: 0;
    border-bottom: 1px solid #888888;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 100px;
    outline: none;
}

.d_none,
.chat_box.d_none {
    display: none !important;
}

.icon-information {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.end_date_label {
    display: inline-block;
    margin-left: 170px;
}

.chat_welcom_screen {
    width: 100%;
    position: relative;
}

.chat_welcom_inner {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chat_welcom_inner img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 40px;
}

.remain_user {
    position: absolute;
    right: 0;
    top: 0;
}

.user_list ul li small {
    background: #f9a720;
    color: #fff;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 11px;
    margin-left: 15px;
}

.icon_upload_wrapper .icon-create {
    position: absolute;
    right: 10px;
    bottom: 14px;
    font-size: 21px;
}

.msg_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 99;
}

.msg_loader svg {
    position: absolute;
    width: 130px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chat_screen.no_scroll {
    overflow: hidden !important;
}

.input_space_form_2 .error_tootltip {
    top: 34px;
    right: 0;
}

.img_upload_wrap input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
}

.img_upload_wrap {
    position: relative;
}

img.adv_img {
    width: 35px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
}

.invoice_list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    max-height: 300px;
    overflow: auto;
    padding-right: 15px;
}

.invoice_list li {
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    align-items: center;
    padding: 15px 0;
}


.invoice_list svg {
    width: 35px;
}

.invoice_list li a {
    margin-left: auto;
    padding: 4px 20px;
}


.cm_dropdown .ant-select-selector {
    background: #EDEDED !important;
    border: 0 !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    padding: 5px 25px !important;
    height: auto !important;
}

.icon_upload_wrapper.cat_banner_img {
    width: 100%;
    margin-bottom: 30px;
    height: 250px;
}

.icon_upload_wrapper.cat_banner_img img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.cat_banner_img img {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 10px;
    vertical-align: top;
}

tr.in__active {
    opacity: 0.4;
}

.hide_pm .ant-picker-content ul:nth-child(3) li:last-child,
.hide_am .ant-picker-content ul:nth-child(3) li:first-child {
    display: none !important;
}

.close_crop {
    cursor: pointer;
    position: absolute;
    background: #842014;
    width: 30px;
    height: 30px;
    top: 34px;
    z-index: 99;
    line-height: 31px;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    right: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.26);
}




.feed_card {
    position: relative;
    font-size: 14px;
}

.feed_head {
    position: relative;
    padding-left: 75px;
    margin-bottom: 20px;
}

.feed_head img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}

.feed_head h3 {
    font-size: 18px;
    color: #3E3E3E;
}

.lcs_wrap {
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 0;
    font-size: 16px;
}

.lcs_wrap > span {
    cursor: pointer;
}

.feed_media {
    margin-bottom: 15px;
}
.feed_media img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}
.feed_m_item {
    width: 100%;
    position: relative;
    padding: 1px;
}

.more_then .feed_m_item {
    width: calc(100% / 2);
    display: inline-block;
    vertical-align: text-top;
}

.clone_icons {
    position: relative;
    min-height: 40px;
    display: inline-block;
}

.clone_icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    font-size: 12px;
}
.clone_icon:hover {
    background: #842014;
    color: #fff !important;
}

.clone_icons.program_pdf .clone_icon {
    right: -20px;
}

.selct_program .ant-radio + * {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 16px;
}



.cm_banner_wrapper {
    position: relative;
}

.cm_banner_wrapper img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.cm_banner_text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    z-index: 9;
}


.cm_banner_text p {
    font-size: 18px;
}


.line_h_2, .shadow_card p {
    line-height: 2.2;
}

.cm_section {
    position: relative;
    padding: 100px 0;
    overflow: hidden;
}

.cm_section.ball_right {
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: cover;
}

.cm_section:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: #FBA52C;
    border-radius: 100%;
    bottom: 0;
    left: -60px;
}

.about_img {
    position: absolute;
    right: -4%;
    top: 100px;
    height: calc(100% - 200px);
    border-radius: 100%;
}

.section_title_center, .section_title_left {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
}


.section_title_left:after {
    content: '';
    width: 120px;
    height: 3px;
    background: #F9A720;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 2px;
}

.cm_counts {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 40px 20px;
    background: #fff;
    box-shadow: 0 3px 50px rgba(0,0,0,0.1);
    border-radius: 20px;
    margin-top: 50px;
}


.cm_banner_wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    opacity: 0.4;
}

.cm_banner_text h2 {
    font-size: 52px;
    color: #fff;
    margin-bottom: 25px;
}

.cm_counts li {
    display: inline-block;
    width: calc(100% / 4);
    text-align: center;
    border-right: 1px solid #B4B4B4;
    position: relative;
}

.cm_counts li h3 {
    color: #842014;
    font-size: 32px;
    margin: 10px 0;
}

.cm_counts li h5 {
    color: #888888;
    font-size: 16px;
    margin: 0;
}

.cm_counts li:last-child {
    border: 0;
}

.edit_btn {
    position: absolute;
    right: 14px;
    top: 10px;
    z-index: 9;
    color: #fff;
    font-size: 40px;
    z-index: 999;
    cursor: pointer;
    display: none;
}

.edit_btn.edit_btn_dark {
    color: #000;
}

.edit_btn.edit_btn_sm {
    color: #000;
    font-size: 18px;
    top: 0;
}

.cm_banner_wrapper:hover .edit_btn,
.cm_section:hover .edit_btn{
    display: block;
}

.cm_section:hover .shadow_card .edit_btn,
.cm_section:hover .cm_counts li .edit_btn {
    display: none;
}

.cm_section:hover .shadow_card:hover .edit_btn,
.cm_section:hover .cm_counts li:hover .edit_btn {
    display: block;
}

.cm_section.ball_right {
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: cover;
}

.cm_section.ball_right:before {
    left: auto;
    right: -60px;
}

.section_title_center {
    text-align: center;
}

.shadow_card {
    box-shadow: 0 3px 50px rgba(0,0,0,0.05);
    padding: 15px;
    padding-top: 50px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    background: #fff;
}

.card_num {
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight: 500;
    background: #842014;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #fff;
    line-height: 60px;
}

.shadow_card h5 {
    font-size: 18px;
    margin-bottom: 20px;
}

.selct_program {
    font-size: 15px;
    margin-bottom: 15px;
}


.doc_view a {
    display: inline-block;
}

.doc_view a svg {
    width: 35px;
}

.cm_addit {
    border: 1px solid #d8d6d6;
    padding: 15px;
    margin-bottom: 30px;
}

.view_btn {
    color: #842014;
    position: relative;
    margin-left: 8px;
    top: 2px;
    cursor: pointer;
    top: -5px;
}

.cm_with_btn .t_text {
    width: 100%;
}

.msg_count {
    position: absolute;
    right: 0;
    top: 50%;
    background: #f9a720;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: translateY(-50%);
}

.cm_excel_upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.cm_excel_upload {
    position: relative;
}

.icon-copy {
    cursor: copy;
}

.icon-copy:hover {
    color: #842014;
}

.cm_on_line {
    font-size: 20px;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    margin-bottom: 18px;
    text-align: center;
    background: #abcf43;
    background: linear-gradient(to right, #abcf43 0%,#51bf5a 100%);
    font-weight: 600;
    text-shadow: 2px 0px 0px rgba(0,0,0,0.16);
}

@media print {
    body * {
      visibility: hidden;
    }
    #section-to-print,
    #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
    }
    #section-to-print .hide_on_print {
      display: none !important;
    }
  }


  .add_question_btn {
    text-align: center;
    border: 2px solid #842014;
    width: 208px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    color: #000000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
}

.add_question_btn:hover {
    background: #842014;
    color: #fff;
}

.cm_questions {
    background: #f7f7f7;
    padding: 15px;
    border-radius: 10px;
}

.cm_questions > i {
    position: absolute;
    right: 15px;
    top: 23px;
    font-size: 24px;
    color: #842014;
    cursor: pointer;
}

.cm_error {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    color: red;
    font-size: 14px;
}

.with_pdf svg {
    width: 30px;
}

.is_split {
    background: #f9ac49 !important;
    color: #fff;
}

.is_payment_split {
    position: absolute;
    right: 20px;
    top: 80px;
    color: red;
    display: flex;
    align-items: center;
}

.payment_check {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    margin-left: 10px;
}

.payment_check input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
}

.payment_check span {
    width: 18px;
    height: 18px;
    border: 2px solid #842015;
    display: inline-block;
    border-radius: 3px;
    position: relative;
}

.payment_check input:checked + span{
    background: #842015;
} 

.payment_check input:checked + span:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 11px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 3px;
    top: 0px;
    transform: rotate(35deg);
}

.cm_top_img img {
    max-height: 140px;
    max-width: 100%;
}

.question_types {
    margin-right: 40px;
    display: flex;
    align-items: center;
}

.cm_ques textarea {
    margin-bottom: 25px;
}


.question_types p {
    font-size: 13px;
    white-space: nowrap;
    margin: 0;
    margin-right: 10px;
    color: #842014;
    font-weight: 500;
}

.question_types .cm_swich_wrap {
    margin-right: 40px;
}

.question_types .cm_select__control {
    min-width: 300px;
}

.cm_options li {
    position: relative;
    margin: 10px 0;
}

.cm_options li i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.sidebar ul li a svg {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.sidebar ul li a svg path {
    fill: #8E8E8E;
}

.sidebar ul li a:hover svg path,
.sidebar ul li a.active svg path {
    fill: #842014;
}

.icon_upload_wrapper.district_m img {
    width: 100%;
    height: auto;
    position: static;
    transform: none;
}

.icon_upload_wrapper.district_m {
    height: auto;
    min-height: 200px;
}

.dis_card_img img {
    max-height: 173px;
    margin-bottom: 27px;
    max-width: 100%;
}

.dis_card_img {
    min-height: 200px;
    text-align: center;
}

tr.cancel_booking {
    background: #842015 !important;
    color: #fff;
}

tr.cancel_booking a {
    color: #fff;
}

.nav-link {
    padding: 0.5rem 0.8rem;
}

.tab_group span {
    display: inline-block;
    padding: 5px 20px;
    font-size: 13px;
    background: #e5e5e5;
    border-radius: 4px;
    margin-left: 15px;
    cursor: pointer;
}

.tab_group span.active {
    background: #f8a721;
    color: #fff;
}

.booking_unread_cont {
    position: relative;
}

.booking_unread_cont b {
    position: absolute;
    background: #842014;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 11px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    right: -15px;
    top: -7px;
    border: 2px solid #f7f7f7;
    box-sizing: content-box;
}

.add_school_wrapper {
    position: relative;
    padding-right: 80px;
}

.add_school {
    position: absolute;
    right: 0;
    top: 0;
    background: #F9A720;
    padding: 6px 20px;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;
}

.err_msg {
    color: red;
    font-size: 12px;
}

span.cm_chip {
    display: inline-block;
    padding: 6px 15px;
    background: #eeeeee;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    position: relative;
}

.cat_card .icon__pancil {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
}

.google_sheet_modal {
    min-height: 300px;
    margin: 0 !important;
}


.google_sheet_modal .main_loader {
    position: static !important;
    width: 100px !important;
    height: 100px !important;
}

.google_sheet_modal .main_loader svg {
    width: 100px;
    height: 100px;
    position: static;
    margin: 0 auto;
    display: block;
    transform: none;
}

.google_sheet_wrapper {
    padding: 20px;
}

.google_sheet_wrapper a {
    display: inline-block;
    word-break: break-all;
    font-size: 13px;
}

img.us_flag {
    width: 20px;
}

.quill.is-invalid .ql-toolbar.ql-snow,
.quill.is-invalid .ql-container.ql-snow {
    border: 1px solid #dc3545;
}

.cm_add_user_btn {
    position: absolute;
    right: 15px;
    top: 10px;
}

.profile_upload {
    width: 110px;
    height: 110px;
    margin: 25px auto;
    position: relative;
    border-radius: 100%;
    background: #842015;
}

.profile_upload img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 100%;
}

.profile_upload input {
    position: absolute;
    font-size: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
}

.profile_upload i {
    position: absolute;
    background: #e8e8e8;
    border: 1px solid #f7f7f7;
    color: #842014;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 27px;
    border-radius: 100%;
    right: 0;
    bottom: 0;
}

.check_box_wrapper {
    position: relative;
    padding-left: 30px;
    color: #2b2b2b;
}

.check_box_wrapper input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
}

.pro_wrapper .check_box>span, .pro_wrapper_2 .check_box>span {
    border: 2px solid #000;
}

.check_box>span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
}

.check_box>span:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    border-right: 3px solid #842014;
    border-bottom: 3px solid #842014;
    transform: rotate(35deg);
    top: 1px;
    left: 4px;
    opacity: 0;
}

.check_box_wrapper input:checked+.check_box>span:after {
    opacity: 1;
}

.pro_wrapper label {
    color: #000;
    font-weight: 500;
    margin-right: 10px
}

.check_box_wrapper {
    position: relative;
    padding-left: 30px;
    color: #2b2b2b;
}

.cm_gendr ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.pro_wrapper .check_box>span, .pro_wrapper_2 .check_box>span {
    border: 2px solid #000;
}

.cm_gendr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.spaceform .form-group {
    margin-bottom: 30px;
}

.campers_list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    max-width: 75%;
}

.campers_list > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 10px 0;
    margin-bottom: 15px;
    background: #f7f7f7;
}

.campers_list li img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
}

.campers_list li p {
    margin: 0;
}

.campers_list i {
    cursor: pointer;
    color: #000;
    margin-left: 13px;
}

.campers_list li p b {
    margin-left: 20px;
}

.campers_list li p b:first-child {
    margin-left: 0;
}

.attach_file_btn {
    border: 1px solid #842014;
    width: 130px;
    text-align: center;
    padding: 5px 15px;
    position: relative;
    color: #842014;
}

.attach_file_btn input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
}

.cm_pdf_icon {
    margin: 20px 0;
    position: relative;
}

.cm_pdf_icon svg {
    width: 45px;
}

.cm_pdf_icon i {
    position: absolute;
    left: 35px;
    top: -10px;
    cursor: pointer;
}

.cm_pdf_icon p {
    display: inline-block;
    margin: 0 0 0 10px;
}

.cm_error_modal {
    background: rgba(0,0,0,0.75);
}

.cm_steppes_list {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 15px;
}

.cm_steppes_list li {
    margin-bottom: 5px;
    color: #8e8e8e;
    background: #f8f7f7;
    padding: 14px;
    border-radius: 0px;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
}

.cm_steppes_list li.done {
    background: #f8a721;
    color: #fff;
}

.cm_steppes_list li.active {
    background: #842015;
    color: #fff;
}

.program_btn_group {
    margin-bottom: 20px;
}

.program_btn_group span {
    margin-bottom: 10px;
}

.miniBtnLoader {
    background: #e9e9e9;
    display: inline-block;
    border-radius: 60px;
    padding: 6px 20px;
}

.campers_add_exp li {
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    margin-bottom: 20px;
}

.campers_add_exp li p {
    margin-bottom: 10px;
}

.cm_dark_check .check_box>span {
    border: 2px solid #2b2b2b;
    top: 3px;
}

ul.campers_add_exp {
    padding: 0;
    list-style: none;
}

.check_box_wrapper input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    z-index: 9;
    opacity: 0;
}

.campers_add_exp .check_box_wrapper {
    position: absolute;
    right: 0;
    top: 5px;
}

.c_bor_b {
    border-bottom: 1px solid #b4b4b4;
    padding-bottom: 20px;
    margin-bottom: 20px;
}


.date_heading {
    background: #ebeaea;
    color: #2b2b2b;
    font-weight: 500;
    padding: 6px 15px;
    margin-bottom: 15px;
}

.c_bor_b b {
    display: inline-block;
    min-width: 100px;
    text-align: right;
    color: #000;
}

.cm_success_msg {
    text-align: right;
    color: green;
    margin-top: 10px;
}

.cm_err_msg2 {
    position: absolute;
    right: 15px;
    font-size: 12px;
    color: red;
}

.c_bor_b h4 {
    font-size: 18px;
    color: #842014;
    font-weight: 500;
}

.cm_radio_wrap {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    padding-left: 30px;
    font-weight: 500;
    color: #000;
}

.cm_radio_wrap input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 99;
    opacity: 0;
}

.cm_radio_wrap input:checked+span {
    background: #f9a720;
    border-color: #f9a720;
}

.cm_radio_wrap span {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
    border: 1px solid #888;
    border-radius: 100%;
}

.cm_radio_wrap input:checked+span:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    border-radius: 100%;
    left: 4px;
    top: 4px;
}

.card_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.card_list li {
    display: flex;
    justify-items: center;
    padding: 15px 20px;
    margin-bottom: 20px;
    background: #f3f3f3;
    border-radius: 10px;
    position: relative;
    align-items: center;
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 25%);
    transition: all .3s;
}

.card_list .check_box_wrapper {
    min-height: 23px;
}

.card_list p {
    font-size: 18px;
    color: #000;
    margin: 0;
}

.remove_card {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.p_card_img img {
    max-width: 70px;
    height: 55px;
    margin-right: 35px;
}

.cm_cvc_input {
    width: 80px;
    margin-left: auto;
}

.card_inputs {
    max-width: 350px;
    margin-bottom: 30px;
}

.program_btn_item {
    display: inline-block;
    position: relative;
}

.program_btn_close {
    position: absolute;
    right: 26px;
    top: -12px;
    background: #f7a721;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    line-height: 27px;
    cursor: pointer;
    display: none;
}

.program_btn_item:hover .program_btn_close {
    display: block;
}


.cm_child_options_wrapper li {
    display: flex;
}

.cm_child_options_wrapper p {
    margin: 0;
    width: 50%;
}

.cm_child_options_wrapper li .form-group {
    min-width: 22%;
}

.my_editer .ql-container {
    min-height: 150px;
    max-height: 150px;
    overflow: auto;
}

.date_option {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.date_option p {
    margin: 0;
    margin-right: 15px;
    font-size: 21px;
    color: #000;
}

.date_option .check_wrapper {
    margin-left: 30px;
}

.remove_date {
    padding-top: 40px;
}

.remove_date i {
    cursor: pointer;
    color: #841f15;
}

.end_date_label2 {
    display: inline-block;
    margin-left: 35px;
}


.service_tax_wrapper .form-control {
    height: 40px;
}

.service_tax_wrapper .ant-input-number-input-wrap {
    height: 39px;
}

.service_tax_wrapper .ant-input-number-input {
    padding-left: 20px;
}

.res_msg {
    color: green;
}



.multi_dates ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.multi_dates {
    padding: 15px 0px;
}

.multi_dates ul li {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.cm_selected_date {
    border: 1px solid #dbdbdb;
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cm_selected_date.active {
    background: #841f15;
    color: #ddd;
    border-color: #832015;
}

.participant_count .cm_selected_date {
    position: relative;
    padding-right: 40px;
}

.participant_count .cm_selected_date b {
    position: absolute;
    right: 0;
    top: 0;
    background: #841f15;
    color: #fff;
    width: 20px;
    height: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
}


.selected_program h3 {
    font-size: 20px;
}

.selected_program h5 {
    font-size: 16px;
    margin-bottom: 0;
}


.st_wrapper {
    margin-bottom: 20px;
    position: relative;
}

.st_input {
    display: flex;
    justify-content: center;
    align-items: center;
}

.st_wrapper .cm_swich_wrap {
    position: absolute;
    right: 0;
    top: 0;
}

.my_eror {
    font-size: 12px;
    position: absolute;
    color: red;
    text-align: center;
    width: 100%;
    bottom: -20px;
}

span.pay_active {
    display: inline-block;
    font-size: 11px;
    background: green;
    color: #fff;
    padding: 2px 8px;
    border-radius: 5px;
    margin-left: 10px;
}





.payment-gateway h3 {
    font-size: 24px;
    margin-bottom: 30px;
}

.payment-gateway-card {
    border: 2px solid #e1e1e1;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 6px 6px rgb(0 0 0 / 6%);
    margin-bottom: 30px;
    cursor: pointer;
    min-height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.payment-gateway-card.active {
    border-color: #841f15;
    background: #fff1f0;
}

.payment-gateway-card h4 {
    font-size: 18px;
    font-weight: 500;
}

.payment-gateway-card h2 {
    font-size: 24px;
    font-weight: 500;
}

.payment-gateway-card p {
    margin: 0;
    font-size: 13px;
}

.payment-gateway-card .my_tooltip {
    position: absolute;
    width: 18px;
    right: 10px;
    top: 10px;
    height: 18px;
    display: inline-block;
    line-height: 1;
}

.acc_select {
    display: block;
}

.acc_select .ant-select-selector {
    height: calc(1.5em + 0.75rem + 9px) !important;
    border: 1px solid #8E8E8E !important;
    border-radius: 40px !important;
}

.acc_select .ant-select-selection-placeholder {
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
    padding: 5px !important;
}

.feed_m_item video {
    max-width: 100%;
}

.ant-picker-suffix {
    align-self: inherit;
}

.checkbox_dark > span {
    border: 2px solid #000;
}

ul.product_imgs {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}

ul.product_imgs li {
    display: inline-block;
    width: 90px;
    height: 90px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
}

ul.product_imgs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

ul.product_imgs li i {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}


.product_wide_card_img img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.product_wide_card {
    display: flex;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
}

.product_wide_card_title {
    margin-left: 30px;
}

.product_wide_card_title h4 {
    font-size: 18px;
    margin: 0;
    display: inline-block;
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.product_wide_card_detail {
    text-align: right;
}

.product_wide_card_detail p {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
    color: #841f15;
}

.p_actions {
    margin-top: 43px;
}

.p_actions i {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
}

.pp_fiter .form-control {
    width: 350px;
}

.p_loader .main_loader {
    position: absolute !important;
}

.p_loader  {
    position: relative;
    position: relative;
    min-height: calc(100vh - 230px);
}

.product_wide_card_title small b {
    display: inline-block;
    min-width: 160px;
}


.product_card {
    margin-top: 24px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.11);
    overflow: hidden;
}

.product_card_2 {
    max-width: calc(100% - 20px) !important;
    margin: 0px auto;
    margin-bottom: 40px;
}

.dir_product_slider .product_card {
    overflow: unset;
}

.product_card img {
    border-bottom: 1px solid #d0d0d0;
}

.dir_product_slider .product_card img.img-fluid.pointer {
    border-radius: 9px 10px 0 0;
}

.product_text {
    text-align: left;
    padding: 10px;
}

.product_text h3 {
    font-size: 15px;
    font-weight: 500;
    min-height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    word-break: break-word;
}

.pro_card_price p {
    margin: 0;
    color: #852114;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.btn-secondary {
    background-color: #F9A720;
    border-color: #F9A720;
}

.check_box_wrapper.cm_dark_check_2 {
    position: relative !important;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 100%;
}

.mm_with {
    min-width: 350px;
}

.cm_questions2 .question_types {
    margin-left: 30px;
}

.cm_questions2 .question_types .cm_swich_wrap {
    margin-right: 20px;
}

iframe {
    display: none !important;
}

.color_red {
    color: red;
}

.ans_chip {
    display: inline-block;
    background: #f4f4f4;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
}

.isCommuter_wrapper {
    background: #efefef;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.isCommuter_wrapper p {
    margin-bottom: 10px;
}

.isCommuter_wrapper .check_box_wrapper {
    margin-bottom: 10px;
    margin-top: 20px;
}


@media(min-width: 768px){
    .prog_media.prog_media_mob {
        display: none;
    }
}

@media(max-width: 767px){
    .prog_media.prog_media_desk {
        display: none;
    }
}


.camper_name {
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
    padding: 3px 10px;
    background: #c3c3c3;
    margin-right: 5px;
    border-radius: 3px;
}

@keyframes fillRoted { 
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.btnLoader {
    position: relative;
}

.btnLoader:after {
    content: '';
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #ffc107;
    position: absolute;
    right: 10px;
    top: 0;
    border-radius: 20px;
    margin: 0;
    animation: fillRoted 1s infinite linear;
    box-sizing: initial;
}